const stagingApi = "https://api.staging.smartum.fi";

const config = {
  REACT_APP_ISSUER: stagingApi,
  REACT_APP_CLIENT_ID: "smartum-checkout",
  REACT_APP_GET_ACCOUNTS_API: `${stagingApi}/beneficiary/accounts`,
  REACT_APP_REDIRECT_URI: "http://localhost:3001/",
  REACT_APP_GET_VENUE_API: `${stagingApi}/venues`,
  REACT_APP_MAKE_PAYMENT_API: `${stagingApi}/beneficiary/payments`,
  REACT_APP_JWT_HEADER_KEY: "x-smartum-jwt",
  REACT_APP_JWT_RECEIPT_HEADER_KEY: "x-smartum-receipt",
  REACT_APP_CREATE_PAYMENT_INTENT_API: `${stagingApi}/stripe/payment_intents`,
  REACT_APP_STRIPE_PUBLISHABLE_KEY:
    "pk_test_hg7FAWzfY4vBRYP3bwroXeEt004wi8vWmY",
  PUBLIC_KEY: `-----BEGIN PUBLIC KEY-----MIGbMBAGByqGSM49AgEGBSuBBAAjA4GGAAQAe8rGH96EqvqcIg+DQ/9q8bM+fH1k/31Pw1kCESxdOQ0jy+zIpgZ3SN3U9I7aBKugZe1cejygJBpFNvORPzaIzBEAaJ367fnNZG2chabdJEsUW5qcrffCQNlfcYXvMeZPbps3P3ofwY+Qd2+kpbIUi+Y48n+xe9pEVS5dkH+2lk1DUQc=-----END PUBLIC KEY-----`,
};

const hostname = window?.location?.hostname || "";

const prodApi = "https://api.smartum.fi";

if (
  ["checkout.staging.smartum.fi", "pay.staging.smartum.fi"].includes(hostname)
) {
  config.REACT_APP_REDIRECT_URI = `https://${hostname}/`;
} else if (
  hostname === "checkout.smartum.fi" ||
  hostname === "pay.smartum.fi"
) {
  config.REACT_APP_REDIRECT_URI = `https://${hostname}/`;
  config.REACT_APP_ISSUER = prodApi;
  config.REACT_APP_GET_ACCOUNTS_API = `${prodApi}/beneficiary/accounts`;
  config.REACT_APP_GET_VENUE_API = `${prodApi}/venues`;
  config.REACT_APP_MAKE_PAYMENT_API = `${prodApi}/beneficiary/payments`;
  config.REACT_APP_CREATE_PAYMENT_INTENT_API = `${prodApi}/stripe/payment_intents`;
  config.REACT_APP_STRIPE_PUBLISHABLE_KEY =
    "pk_live_jclGpasBWbMJisw4n6OL8OEp00yrOfvK1o";
  config.PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----MIGbMBAGByqGSM49AgEGBSuBBAAjA4GGAAQA4c5s+DBP4novRcrtjuPwjb5jzcoUsStZrh1dMoKJyTz6+McVmBNlLsTNMGLLWCpGj7ubbBhSvyWQojJkSt8h8wwAffURUcre8DsKtO0XJbtAMBGs+NIQzZCFhyeIqA2LgW7b/ToNNbZgvQGpFRk2X3qhjEtGUGlUWHDobVU/nNKZa6U=-----END PUBLIC KEY-----`;
}

export default config;
